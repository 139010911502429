const { auto } = require("@popperjs/core");

let init = document.querySelector('.header__top-numbers-init svg')
if(init) {
    init.addEventListener('click', () => {
        document.querySelector('.header__top-numbers-init a').click();
    })
}
let binotelTrigger = document.querySelectorAll('[data-binotel-trigger]');
if(binotelTrigger) {
    binotelTrigger.forEach(item => {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            let data = event.target.getAttribute('data-binotel-trigger');
            document.querySelector(`[data-binotel-observer="${data}"]`)?.click();
        })
    })
}



// export function headerSelect() {
// 	let firstInit = document.querySelectorAll('.header__menu-title')
// 	let firstList = document.querySelectorAll('.header__menu-sublist')

//     let lastInit = document.querySelectorAll('.header__menu-sublist-title')
//     let lastList = document.querySelectorAll('.header__menu-lastlist')
//     let actives = document.getElementsByClassName('title-active')
//     let subActives = document.getElementsByClassName('subtitle-active')
//     let body = document.querySelector('body')
//     var index

//     if(firstInit) {
//         // firstInit.forEach((element) => {
//         //     element.addEventListener('click', () => {
//         //         // console.log(element.nextElementSibling)

//         //     })
//         // });
//         for (let i = 0; firstInit.length > i; i++) {
//             let element = firstInit[i]
//             element.onclick = function() {
//                 index = i
//                 // console.log(element)
//                 let active = actives[0]
//                 if(active && active.nextElementSibling) {
//                     active.nextElementSibling.classList.remove('first-list_active')
//                     for (let i = 0; lastList.length > i; i++) {
//                         lastList[i].classList.remove('lastlist-active')
//                     }
//                     for (let i = 0; lastInit.length > i; i++) {
//                         lastInit[i].classList.remove('subtitle-active')
//                     }
//                     active.classList.remove('title-active')
//                 } if(active !== this && this.nextElementSibling) {
//                     this.nextElementSibling.classList.add('first-list_active')
//                     this.classList.add('title-active')
//                 }

//             }
//         }
//         lastInit.forEach(i => {
//             i.addEventListener('mouseenter', function(e) {
//                 if (!e.relatedTarget.classList.contains('header__menu-sublist')) return;
//                 // console.log('click')

//                 // console.log(element.nextElementSibling)
//                 let subActive = subActives[0]
//                 if(subActive && subActive.nextElementSibling) {
//                     subActive.nextElementSibling.classList.remove('lastlist-active')
//                     subActive.classList.remove('subtitle-active')
//                 } if(subActive !== this && this.nextElementSibling) {
//                     this.nextElementSibling.classList.add('lastlist-active')
//                     this.classList.add('subtitle-active')
//                 }
//                 // i.nextElementSibling.classList.toggle('lastlist-active')
//                 // i.classList.toggle('subtitle-active')
//             })
//             // i.addEventListener('mouseleave',function (e) {
//             //     console.log(e.relatedTarget);
//             //     // if (!e.relatedTarget.classList.contains('header__menu-sublist')) return;
//             //     let subActive = subActives[0]
//             //     if(subActive && subActive.nextElementSibling) {
//             //         subActive.nextElementSibling.classList.remove('lastlist-active')
//             //         subActive.classList.remove('subtitle-active')
//             //     }
//             // } );
//         });

//         body.addEventListener('click', (event) => {
//             const self = event.target.closest('.header__menu-list');

//             if (!self) {


//                 if(firstList[index]!=undefined){
//                     firstList[index].classList.remove('first-list_active')
//                     firstInit[index].classList.remove('title-active')
//                 }
//                 // console.log(lastList)
//                 for (let i = 0; lastList.length > i; i++) {
//                     // console.log("asdasdasd")
//                     lastList[i].classList.remove('lastlist-active')
//                 }
//                 for (let i = 0; lastInit.length > i; i++) {
//                     lastInit[i].classList.remove('subtitle-active')
//                 }
//             }
//         })

//     }
// }
// headerSelect();(





// console.log();


// export function menuInit(){
//     const menuTrigger = document.querySelector(".header__burger");
//     const topNav = document.querySelector(".js-topPushNav");
//     const openLevel = document.querySelectorAll(".header__open-level");
//     const closeLevel = document.querySelectorAll(".header__close-level");
//     const closeLevelTop = document.querySelectorAll(".close-first-level");
//     const navLevel = document.querySelectorAll(".js-pushNavLevel");
//     const screens = document.querySelectorAll(".screen");

//     const activeMenuItem = document.querySelector('.textSubLevel.active');

//     function openPushNav() {
//         topNav.classList.add("isOpen");
//         document.body.classList.add("pushNavIsOpen");

//         const menuLvl = activeMenuItem.parentElement.parentElement
//         const menuLvl2 = menuLvl.parentElement.parentElement

//         if (menuLvl.tagName === "UL") {
//             menuLvl.classList.add('isOpen')
//         }
//         if (menuLvl2.tagName === "UL") {
//             menuLvl2.classList.add('isOpen')
//         }
//     }

//     function closePushNav() {
//         topNav.classList.remove("isOpen");
//         navLevel.forEach(level => level.classList.remove("isOpen"));
//         document.body.classList.remove("pushNavIsOpen");
//     }
//     if(menuTrigger) {
//         menuTrigger.addEventListener("click", function (e) {
//             e.preventDefault();
//             if (topNav.classList.contains("isOpen")) {
//                 closePushNav();
//             } else {
//                 openPushNav();
//             }
//         });
//     }

//     if(openLevel){
//         openLevel.forEach(level => {
//             level.addEventListener("click", function () {
//                 this.nextElementSibling.classList.add("isOpen");
//             });
//         });
//     }

//     if(closeLevel){
//         closeLevel.forEach(level => {
//             level.addEventListener("click", function () {
//                 this.closest(".js-pushNavLevel").classList.remove("isOpen");
//             });
//         });
//     }


//     closeLevelTop.forEach(level => {
//         level.addEventListener("click", function () {
//             closePushNav();
//         });
//     });

//     screens.forEach(screen => {
//         screen.addEventListener("click", function () {
//             closePushNav();
//         });
//     });
// }
// menuInit()



// export function menuInitCabinet(){
//     const menuTrigger = document.querySelector(".header__burger-cabinet");
//     const topNav = document.querySelector(".js-topPushNav-profile");
//     const openLevel = document.querySelectorAll(".header__open-level");
//     const closeLevel = document.querySelectorAll(".header__close-level");
//     const closeLevelTop = document.querySelectorAll(".close-first-level");
//     const navLevel = document.querySelectorAll(".js-pushNavLevel");
//     const screens = document.querySelectorAll(".screen");

//     function openPushNav() {
//         topNav.classList.add("isOpen");
//         document.body.classList.add("pushNavIsOpen");
//     }

//     function closePushNav() {
//         topNav.classList.remove("isOpen");
//         navLevel.forEach(level => level.classList.remove("isOpen"));
//         document.body.classList.remove("pushNavIsOpen");
//     }
//     if(menuTrigger) {
//         menuTrigger.addEventListener("click", function (e) {
//             e.preventDefault();
//             if (topNav.classList.contains("isOpen")) {
//                 closePushNav();
//             } else {
//                 openPushNav();
//             }
//         });
//     }


//     openLevel.forEach(level => {
//         level.addEventListener("click", function () {
//             this.nextElementSibling.classList.add("isOpen");
//         });
//     });

//     closeLevel.forEach(level => {
//         level.addEventListener("click", function () {
//             this.closest(".js-pushNavLevel").classList.remove("isOpen");
//         });
//     });

//     closeLevelTop.forEach(level => {
//         level.addEventListener("click", function () {
//             closePushNav();
//         });
//     });

//     screens.forEach(screen => {
//         screen.addEventListener("click", function () {
//             closePushNav();
//         });
//     });
// }
// menuInitCabinet()

// Отримуємо елемент хедера, до якого будемо додавати/видаляти клас


function scrollDetect() {
    var lastScroll = 0;
    const header_on_scroll = document.querySelector('header');

    if (header_on_scroll) {
        window.onscroll = function () {
            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

            if (currentScroll > lastScroll && currentScroll >= 100) {
                // Scrolling down by 50px or more
                header_on_scroll.classList.add('scrolled-down');
                lastScroll = currentScroll;
            } else if (lastScroll > currentScroll && currentScroll < lastScroll - 50) {
                // Scrolling up by 50px or more
                header_on_scroll.classList.remove('scrolled-down');
                lastScroll = currentScroll;
            }
        };
    }
}
scrollDetect();


function menu(){
    const menu = document.querySelector('.header__bottom')
    const menuTrigger = document.querySelector(".header__burger");
    const overlay = document.querySelector(".menu-overlay")
    const body = document.querySelector("body")
    const firstList = document.querySelector('.menu-list.first-list')
    const menuClose = firstList.children[0].querySelector('.menu-close')
    const firstItems = firstList.children[1].children


    const activeItems = firstList.querySelectorAll('.menu-item .active-menu-link')
    const activeItem = activeItems[0]
    function checkingActiveLink(){
        if(activeItem) {
            const secondLevel = activeItem.closest('.menu-list .second-list')
            const thirdLevel = activeItem.closest('.menu-list .third-list')
            if(secondLevel){
                secondLevel.click()
                firstList.style.height = secondLevel.children[0].clientHeight + secondLevel.children[1].clientHeight + 'px'
                secondLevel.classList.add('isOpen')
                if( thirdLevel ){
                    const thirdLevelHeight = thirdLevel.children[0].clientHeight + thirdLevel.children[1].clientHeight
                    secondLevel.style.height = thirdLevelHeight + 'px'
                    firstList.style.height = thirdLevelHeight + 'px'
                    thirdLevel.classList.add('isOpen')
                    thirdLevel.click()
                }
            }
        }
    }
    menuTrigger.addEventListener("click", function (){
        checkingActiveLink()
        menu.classList.add('active-menu')
        overlay.classList.add('active-overlay-menu')
        body.classList.add("noScroll")
    })

    function checkActiveList(){
        const allLists = document.querySelectorAll('.menu-list')
        allLists.forEach((list) => {
            list.style.height = 'auto'
            if(list.classList.contains('isOpen')){
                list.classList.remove('isOpen')
            }
        })
    }

    overlay.addEventListener("click", function (){
        checkActiveList()
        menu.classList.remove('active-menu')
        overlay.classList.remove('active-overlay-menu')
        body.classList.remove("noScroll")
    })


    menuClose.addEventListener("click", function (){
        checkActiveList()
        menu.classList.remove('active-menu')
        overlay.classList.remove('active-overlay-menu')
        body.classList.remove("noScroll")
    })

    Array.from(firstItems).forEach((firstItem) => {
        firstItem.addEventListener("click", function (event) {
            const secondList = firstItem.querySelector('.menu-list.second-list')
            if(secondList){
                event.stopPropagation()
                const secondListHeight = secondList.children[0].clientHeight + secondList.children[1].clientHeight
                firstList.style.height = secondListHeight +'px'
                secondList.classList.add("isOpen");
                if(secondList.classList.contains("isOpen")){
                    event.stopPropagation()
                    // const closeSecondList = secondList.children[0].querySelector('.menu-close')
                    const closeSecondList = secondList.children[0]
                    const closeEvent = closeSecondList.addEventListener("click", function (event) {
                        event.stopPropagation();
                        secondList.classList.remove("isOpen");
                        closeSecondList.removeEventListener('click',closeEvent)
                        firstList.style.height = 'auto';
                    });
                }
                const secondItems = secondList.children[1].children
                Array.from(secondItems).forEach((secondItem) => {
                    secondItem.addEventListener("click", function (event) {
                        const thirdList = secondItem.querySelector('.menu-list.third-list')
                        event.stopPropagation();
                        if(thirdList){
                            const thirdListHeight = thirdList.children[0].clientHeight + thirdList.children[1].clientHeight
                            firstList.style.height = thirdListHeight +'px'
                            thirdList.classList.add("isOpen");
                            secondList.style.height = thirdListHeight +'px';
                            event.stopPropagation();
                            if(thirdList.classList.contains("isOpen")){
                                event.stopPropagation()
                                // const closeThirdList = thirdList.children[0].querySelector('.menu-close')
                                const closeThirdList = thirdList.children[0]
                                closeThirdList.addEventListener("click", function (event) {
                                    thirdList.classList.remove("isOpen");
                                    firstList.style.height = secondListHeight +'px';
                                    secondList.style.height = 'auto';
                                    event.stopPropagation();
                                });
                            }
                        }
                    })
                })
            }
        });
    })
}

var screenWidth = window.innerWidth;

if(screenWidth < 991){
    menu()
}
