
// for language modal
require("./get-language.js");
require("./main.js");
require("./header.js");
require("./datepicker.js");
require("./mask.js");
require("./swipers/swipers.js");
require("./filters.js");
require("./form.js");
require("./binotel.js");
require("./custom.js");
require("./pop-up.js");
require("./reviews.js");
